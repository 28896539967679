<template>
  <CContainer>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol col="4">
                <CButton color="light" @click="tipo_formulario = 1"
                  >Crear</CButton
                >
                <CButton color="light" @click="tipo_formulario = 3"
                  >Calcular Similitud</CButton
                >
              </CCol>
              <CCol col="4">
                <h3>Palabras Spam</h3>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="6">
        <template v-if="tipo_formulario == 1">
          <CCard>
            <CCardHeader>
              <h5>Agregar Palabra</h5>
            </CCardHeader>
            <CCardBody>
              <CForm validate @submit.prevent="crearnuevoPalabra($event)">
                <CInput
                  label="Palabra"
                  placeholder="Palabra"
                  autocomplete="off"
                  minlength="1"
                  required
                  was-validated
                  v-model="form_crear_palabra.palabra"
                />
                <CSelect
                  label="Clase"
                  placeholder="Seleccionar Clase"
                  :options="[
                    { value: 'empresarial', label: 'Empresarial' },
                    { value: 'peligrosa', label: 'Peligrosa' },
                  ]"
                  required
                  was-validated
                  :value.sync="form_crear_palabra.clase"
                />
                <CInput
                  label="Porcentaje Similitud"
                  placeholder="Porcentaje Similitud"
                  autocomplete="off"
                  minlength="2"
                  required
                  was-validated
                  v-model="form_crear_palabra.porcentaje"
                />
                <hr />
                <CButton size="sm" color="success" type="submit">
                  Crear Palabra
                </CButton>
                <CButton
                  size="sm"
                  color="danger"
                  type="submit"
                  @click="tipo_formulario = 0"
                >
                  Cancelar
                </CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </template>
        <template v-if="tipo_formulario == 2">
          <CCard>
            <CCardHeader>
              <h5>Actualizar Palabra</h5>
            </CCardHeader>
            <CCardBody>
              <CForm validate @submit.prevent="formularioEditarPalabra($event)">
                <CInput
                  label="Palabra"
                  placeholder="Palabra"
                  autocomplete="off"
                  minlength="1"
                  required
                  was-validated
                  v-model="form_editar_palabra.palabra"
                />
                <CSelect
                  label="Clase"
                  placeholder="Seleccionar Clase"
                  :options="[
                    { value: 'empresarial', label: 'Empresarial' },
                    { value: 'peligrosa', label: 'Peligrosa' },
                  ]"
                  required
                  was-validated
                  :value.sync="form_editar_palabra.clase"
                />
                <CInput
                  label="Porcentaje Similitud"
                  placeholder="Porcentaje Similitud"
                  autocomplete="off"
                  minlength="2"
                  required
                  was-validated
                  v-model="form_editar_palabra.porcentaje"
                />
                <hr />
                <CButton size="sm" color="success" type="submit">
                  Actualizar Palabra
                </CButton>
                <CButton
                  size="sm"
                  color="danger"
                  type="submit"
                  @click="tipo_formulario = 0"
                >
                  Cancelar
                </CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </template>
        <template v-if="tipo_formulario == 3">
          <CCard>
            <CCardHeader>
              <h5>Calcular Simulitud Palabra</h5>
            </CCardHeader>
            <CCardBody>
              <CForm
                validate
                @submit.prevent="calcularSimilitudPalabra($event)"
              >
                <CInput
                  label="Mensaje"
                  placeholder="Mensaje"
                  autocomplete="off"
                  minlength="1"
                  required
                  was-validated
                  v-model="form_calcular_porcentaje_palabra.mensaje"
                />
                <CInput
                  label="Palabra a comparar"
                  placeholder="Palabra a comparar"
                  autocomplete="off"
                  minlength="2"
                  required
                  was-validated
                  v-model="form_calcular_porcentaje_palabra.palabra"
                />
                <CInput
                  label="Porcentaje Similitud"
                  placeholder="Porcentaje Similitud"
                  autocomplete="off"
                  minlength="2"
                  disabled
                  v-model="form_calcular_porcentaje_palabra.porcentaje"
                />
                <hr />
                <CButton size="sm" color="success" type="submit">
                  Calcular
                </CButton>
                <CButton
                  size="sm"
                  color="danger"
                  type="submit"
                  @click="tipo_formulario = 0"
                >
                  Cancelar
                </CButton>
              </CForm>
            </CCardBody>
          </CCard>
        </template>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-center">
              <CCol col="6">
                <h5 class="text-center">
                  Cantidad de palabras creadas: {{ items.length }}
                </h5>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              column-filter
            >
              <template #acciones="{ item }">
                <td>
                  <CButton
                    size="sm"
                    color="info"
                    @click="abrirmodalEditarPalabra(item)"
                  >
                    Editar
                  </CButton>
                </td>
                <td>
                  <CButton
                    size="sm"
                    color="danger"
                    @click="abrirmodalEliminarPalabra(item)"
                  >
                    Eliminar
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
      <CModal
        :show.sync="modal_eliminar_palabra"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="sm"
        color="dark"
      >
        <CRow>
          <CCol col="12">
            Usted esta Apunto de Eliminar la Palabra :
            <strong>{{ form_eliminar_palabra.nombre }}</strong>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">Eliminar Palabra</h6>
          <CButtonClose
            @click="modal_eliminar_palabra = false"
            class="text-white"
          />
        </template>
        <template #footer>
          <CButton @click="modal_eliminar_palabra = false" color="danger"
            >Cancel</CButton
          >
          <CButton color="success" @click="eliminarPalabraPorId()"
            >Aceptar</CButton
          >
        </template>
      </CModal>
    </div>
  </CContainer>
</template>


<script>
import EventBus from "../../modules/bus";
import { CChartPie } from "@coreui/vue-chartjs";
export default {
  name: "PalabrasSpam",
  components: { CChartPie },
  data() {
    return {
      items: [],
      fields: [
        { key: "palabra", label: "Palabra", _classes: "font-weight-bold" },
        { key: "clase", label: "Clase" },
        { key: "porcentaje_similitud", label: "Porcentaje Similitud" },
        {
          key: "acciones",
          label: "",
          _style: { width: "1%" },
          sorter: false,
          filter: false,
        },
      ],
      activePage: 1,
      collapseDuration: 0,
      form_crear_palabra: {
        palabra: "",
        clase: "",
        porcentaje: "",
      },
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      mensaje_success: "Palabra Creada Correctamente.",
      modal_eliminar_palabra: false,
      form_eliminar_palabra: {
        nombre: "",
        id: "",
      },
      form_editar_palabra: {
        palabra: "",
        clase: "",
        porcentaje: "",
        id: "",
      },
      form_calcular_porcentaje_palabra: {
        mensaje: "",
        palabra: "",
        porcentaje: "",
      },
      labelsChart: [],
      dataChart: [],
      tipo_formulario: 0,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
      }
    },
    rowClicked(item, index) {
      this.$router.push({ path: `users/${index + 1}` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    getPalabras() {
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .get("/sms/palabras")
        .then((response) => {
          this.items = response.data;
          this.items.map((item, id) => {
            if (id % 2 == 0) {
              item["_classes"] = "table-now";
            }
            return item;
          });
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    toggleDetails(item) {
      this.$set(this.items[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    crearnuevoPalabra(evt) {
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/palabras", {
          palabra: this.form_crear_palabra.palabra,
          clase: this.form_crear_palabra.clase,
          porcentaje: this.form_crear_palabra.porcentaje,
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            this.mensaje_success = "Palabra Creada Correctamente.";
            evt.target.disabled = false;
            this.form_crear_palabra.palabra = "";
            this.form_crear_palabra.clase = "";
            this.form_crear_palabra.porcentaje = "";
            this.mostrarNotificacion++;
            this.tipo_formulario = 0;
            this.getPalabras();
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                //mensajeError += `${element.mensaje} \n`
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    eliminarPalabraPorId() {
      this.modal_eliminar_palabra = false;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .delete("/sms/palabras/" + this.form_eliminar_palabra.id)
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            let nombre = this.form_eliminar_palabra.nombre;
            this.form_eliminar_palabra.id = "";
            this.form_eliminar_palabra.nombre = "";
            this.mostrarNotificacion++;
            this.getPalabras();
            (this.mensaje_success = `Palabra ${nombre} Eliminado Correctamente.`),
              setTimeout(() => {
                this.mensaje_success = "Palabra Creado Correctamente.";
              }, 6000);
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                //mensajeError += `${element.mensaje} \n`
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    formatoFecha(fecha) {
      let fechaformato = fecha.split(".")[0];
      return fechaformato.replace("T", " ");
    },
    abrirmodalEliminarPalabra(item) {
      this.modal_eliminar_palabra = true;
      this.form_eliminar_palabra.nombre = item.palabra;
      this.form_eliminar_palabra.id = item.id;
    },
    abrirmodalEditarPalabra(item) {
      this.tipo_formulario = 2;
      this.form_editar_palabra.palabra = item.palabra;
      this.form_editar_palabra.id = item.id;
      this.form_editar_palabra.porcentaje = item.porcentaje_similitud;
      this.form_editar_palabra.clase = item.clase;
    },
    formularioEditarPalabra(evt) {
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .put("/sms/palabras/" + this.form_editar_palabra.id, {
          palabra: this.form_editar_palabra.palabra,
          clase: this.form_editar_palabra.clase,
          porcentaje: this.form_editar_palabra.porcentaje,
        })
        .then((response) => {
          let data = response.data;
          if (data == "OK") {
            this.mensaje_success = "Palabra Actualizada Correctamente.";
            evt.target.disabled = false;
            this.form_editar_palabra.palabra = "";
            this.form_editar_palabra.clase = "";
            this.form_editar_palabra.porcentaje = "";
            this.form_editar_palabra.id = "";
            this.mostrarNotificacion++;
            this.tipo_formulario = 0;
            this.getPalabras();
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                //mensajeError += `${element.mensaje} \n`
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    calcularSimilitudPalabra(evt) {
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/similitud/palabra", {
          palabra: this.form_calcular_porcentaje_palabra.palabra,
          mensaje: this.form_calcular_porcentaje_palabra.mensaje,
        })
        .then((response) => {
          let data = response.data;
          if (data.status == "OK") {
            this.mensaje_success = "Palabra Calculada Correctamente.";
            evt.target.disabled = false;
            this.mostrarNotificacion++;
            this.form_calcular_porcentaje_palabra.porcentaje = data.porcentaje;
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                //mensajeError += `${element.mensaje} \n`
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
          evt.target.disabled = false;
        })
        .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
  },
  mounted() {
    setTimeout(() => {
      this.getPalabras();
    }, 100);
  },
  computed: {
    defaultDatasets() {
      return [
        {
          backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
          data: this.dataChart,
        },
      ];
    },
  },
};
</script>
